.scrollable-div {
    overflow: auto;
    padding-right: 15px;
}

.container-div {
    display: flex;
    flex-wrap: wrap;
}

.left-div {
    flex-grow: 2;
    margin: 5px;
}

.right-div {
    flex-grow: 1;
    margin: 5px;
}

div::-webkit-scrollbar {
    width: 5px;

}

/* Track */
div::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
div::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 7.5px;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
    background: #999;
}
