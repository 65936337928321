.scanner-card {
    width: 35%;
    text-align: center;
}

.scanner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
}

.scanner-container-exit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 76.5vh;
}

@media only screen and (max-width: 768px) {
    .scanner-card {
        width: 85%;
    }
}
