.site-editor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.site-editor {
    width: 60%;
}


@media only screen and (max-width: 768px) {
    .site-editor {
        width: 90%;
    }
}
