#components-layout-demo-side .logo {
    height: 32px;
    /* background: rgba(255, 255, 255, 0.2); */
    /* margin: 16px; */
    margin-left: 30px!important;
}

.site-layout .site-layout-background {
    background: #fff;
}

