.container-div {
    display: flex;
    flex-wrap: wrap;
}

.left-div {
    flex-grow: 2;
    margin: 5px;
}

.right-div {
    flex-grow: 1;
    margin: 5px;
}


@media only screen and (min-width: 1200px) {
    .left-card {
        height: 90vh;
    }
}
