.login-card {
    width: 25%;
    padding: 40px 10px 0 10px;
}

.register-card {
    max-height: 90vh;
    width: 35%;
    padding: 40px 10px 0 10px;
}

.content {
    background-color: #e4e4e4;
    min-height: 100vh;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .login-card {
        width: 85%;
    }

    .register-card {
        width: 85%;
    }
}

